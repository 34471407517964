import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placegraph,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeUndo,
  placeRedo,
  placeStartOver,
  drawDash,
  placeTapeVert,
  placeTapeHori,
  placeMiddleText,
  placePlay,
  placeTest,
  placeBoldText,
  setColor,
  drawSq,
  drawRec,
  drawRegPoly,
  drawLine,
  placeLeftText
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS////////////////////////////////
var graph =createSpace(-13,3,-9,7);
makeResponsive(graph);
graph.suspendUpdate();
graph.options.layer['image'] =13;
graph.options.layer['circle'] =13;
graph.options.layer['chart'] =13;
graph.options.layer['sector'] =13;
graph.options.layer['line'] =13;
graph.options.image.highlight=false;
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
const height = (boundingBox[1]-boundingBox[3])/2;
/**********************PUT AXES **********************/
//var ax = createAxes(graph);
/***************** PUT AXES TITLE *******************/
//ax[0].setAttribute({name:'X'});
//ax[1].setAttribute({name:'Y'});
/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var scoreCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
/*************************ANSWERS*********************/
var index_selected_answer = -1;
const XList = ['&alpha; & &delta;\'', '&alpha; & &alpha;\'', '&delta; & &alpha;\'', '&gamma; & &alpha;\'', '&beta; & &beta;\''];
const answers = [['Corrsponding angles', 'Alternate exterior angles ', 'Alternate interior angles', 'Interior angles on same side'], ['Corrsponding angles', 'Alternate exterior angles ', 'Alternate interior angles', 'Interior angles on same side'],['Corrsponding angles', 'Alternate exterior angles ', 'Alternate interior angles', 'Interior angles on same side'],['Corrsponding angles', 'Alternate exterior angles ', 'Alternate interior angles', 'Interior angles on same side'], ['Corrsponding angles', 'Alternate exterior angles ', 'Alternate interior angles', 'Interior angles on same side']];
const index_right_answer = [1,0,2,3,0];
/**********************************************************************************/
var pTrans11 = graph.create('point', [-6,-6],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pTrans12 = graph.create('point', [0, -1],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
placeLeftText(graph, 0.25, -0.75, 'l');
var pVert11  = graph.create('point', [-3,-6],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pVert12 = graph.create('point', [-3,2],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
placeLeftText(graph, -3, 2.5, 'n');
//
var pTrans21 = graph.create('point', [-6, -3],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pTrans22 = graph.create('point', [0, 2],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
placeLeftText(graph, 0.25, 2.25, 'm');
//Arrows
var arrVert11 = drawSegment(graph, pVert11, pVert12);
arrVert11.setAttribute({strokeColor:'blue'});
var arrTrans11 = drawSegment(graph, pTrans21, pTrans22);
arrTrans11.setAttribute({strokeColor:'red'});
var arrTrans21 = drawSegment(graph, pTrans11, pTrans12);
arrTrans21.setAttribute({strokeColor:'red'});
//Angles
var angle1 = graph.create('angle', [arrVert11, arrTrans11 , 1 ,-1],{name:'&alpha;' , visible:()=>i==0||i==1||i==5&&k==0, radius:0.5, strokeColor:'blue', fillColor:'blue', label: {color: 'blue', offset: [-5 , 15],fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle2 = graph.create('angle', [arrTrans11 , arrVert11, 1 ,1],{name:'&beta;' , visible:()=>i==4&&k==0,  strokeColor:'purple', fillColor:'purple', label: {color: 'black', offset: [-5 , 15],fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle3 = graph.create('angle', [arrTrans11 , arrVert11, -1 ,-1],{name:'&gamma;' , visible:()=>i==3&&k==0,  strokeColor:'green', fillColor:'green', label: {color: 'green', offset: [-5 ,10],fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle4 = graph.create('angle', [arrVert11, arrTrans11 , -1 ,1],{name:'&delta;' , visible:()=>i==2&&k==0,  strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [5 ,0],fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
/////////////////////////////////////////////
var angle5 = graph.create('angle', [arrVert11, arrTrans21 , 1 ,-1],{name:'&alpha;\'' , visible:()=>i==1||i==2||i==3&&k==0,  strokeColor:'blue', fillColor:'blue', label: {color: 'blue', offset: [-5 ,10],fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle6 = graph.create('angle', [arrTrans21 , arrVert11, 1 ,1],{name:'&beta;\'' , visible:()=>i==4&&k==0,  strokeColor:'purple', fillColor:'purple', label: {color: 'black', offset: [-5 , 15],fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle7 = graph.create('angle', [arrTrans21 , arrVert11, -1 ,-1],{name:'&gamma;\'' , visible: false,  strokeColor:'green', fillColor:'green', label: {color: 'green', offset: [-5 , 10],fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle8 = graph.create('angle', [arrVert11, arrTrans21 , -1 ,1],{name:'&delta;\'' , visible:()=>i==0&&k==0,  strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [5 , 10],fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
/***********************************************************/
var i=0; var k=0; var vis=false;
var question = placeQuestion(graph, ()=>'The angles '+ XList[i]+' form a pair of &mdash;');
var comment = placeComment(graph, '');
var note = writeHTMLText(graph, positionX, positionY+height/2., 'You did not make a selection.');
note.setAttribute({visible:false, color:'red'});
function alert(){
    note.setAttribute({visible:false});
}
///////////////////////////GRAPHICS MODULES//////////////////
var bck =placeWhite(graph);
var show =placeBCircles(graph);
var hide=placeCircles(graph);
var check = placeChecks(graph);
var cross = placeCross(graph);
var exclaim = placeExclaim(graph);
var pointers = placeFingers(graph);
hide[0].setAttribute({visible:false});
/***************************BUTTON MODULES******************/
var test =placeTest(graph,'left');
hoverMe(graph, test, 'Check Your Answer', -20, 0);
/**/
var next = placeShuffle(graph);
hoverMe(graph, next, 'Next Question', 0, 0);
/**/
var redo = placeStartOver(graph);
redo.setAttribute({visible:false});
hoverMe(graph, redo, 'Start Over', 0, 0);
/***************************************/
///////////////////////////////////////////////////////////////
var ansList = ['Corrsponding angles', 'Alternate exterior angles ', 'Alternate interior angles', 'Interior angles on same side'];
var ansArray = placeAnswers(graph, ansList);
for(let ij=0; ij<=3; ij++)
{
  ansArray[ij].on('down', function(){
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray[ij].setAttribute({color:'black'});
  pointers[ij].setAttribute({visible:true});
  index_selected_answer = ij.valueOf();})
}
////////////////////////////////////////////////////////////////////////
var hint =writeHTMLText(graph, positionX, positionY, '<ol> <li> Tap on <i class="redoer ma-1"></i> to go to next step.</li> <br><li>Tap on <i class="tester ma-1"></i> to check your answer.</li><br><li>Tap on <i class="undoer ma-1"></i> button to start over.</li> </ol>');
hint.setAttribute({visible:false, color:'white'});
/**************************TEST BUTTON ***********************/
test.on('down', function()
{
  note.setAttribute({visible:false});
  if(i<=XList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<XList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<XList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
      }
	  else if (index_selected_answer == -1 && yourTotal<XList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
        note.setAttribute({visible:true});
        setTimeout(function(){alert(); }, 400);
	  }
  }
});
/*****************************SHUFFLE BUTTON ***********************/
next.on('down', function(){
  note.setAttribute({visible:false});
  if(i<=XList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<XList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<XList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
		    yourWrong = yourWrong+1;
      }
	  else if (index_selected_answer == -1 && yourTotal<XList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
		    yourMissed = yourMissed+1;
	  }
	  yourTotal = yourScore + yourWrong + yourMissed;
  }
  if(i==XList.length-1)
  {
  //eraseAngles();
  k=1;
  bck.setAttribute({opacity:1});
  scoreCard.setAttribute({visible:true});
  missedCard.setAttribute({visible:true});
  wrongCard.setAttribute({visible:true});
  redo.setAttribute({visible:true});
  ansList[0]=answers[0][0];
  ansList[1]=answers[0][1];
  ansList[2]=answers[0][2];
  ansList[3]=answers[0][3];
  ansArray.forEach(function(item){item.setAttribute({visible:false})});
  question.setAttribute({visible:false});
  //alpha.setAttribute({visible:false});
  //beta.setAttribute({visible:false});
  }
  else{
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    i=i+1;
    ansList[0]=answers[i][0];
    ansList[1]=answers[i][1];
    ansList[2]=answers[i][2];
    ansList[3]=answers[i][3];
  }
  hide[i].setAttribute({visible:false});
  hint.setAttribute({visible:false});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  index_selected_answer = -1;
  /////////////////////////ALL CHANGES GO HERE////////////////////////

  ///////////////////////////////////////////////////////////////////
});
/************************************************/
redo.on('down', function(){
	//Hiding all blue circles
	  hide.forEach(function(item){item.setAttribute({visible:true})});
    hide[0].setAttribute({visible:false});
  //Hiding all check marks
    check.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all cross marks //
    cross.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all exclaimation marks
	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
  ///////////////resetting scores//////////////////
    note.setAttribute({visible:false});
	  yourScore=0;
	  yourMissed=0;
	  yourWrong=0;
	  yourTotal=0;
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
  /////////////////////////////////////////////
	  i=0; k=0;
	  ansList[0]=answers[0][0];
	  ansList[1]=answers[0][1];
	  ansList[2]=answers[0][2];
	  ansList[3]=answers[0][3];
	  bck.setAttribute({opacity:0});
	  pointers.forEach(function(item){item.setAttribute({visible:false})});
    ansArray.forEach(function(item){item.setAttribute({visible:true, color:'grey'})});
	  question.setAttribute({visible:true});
    comment.setAttribute({visible:true});
	  hint.setAttribute({visible:false});
	  index_selected_answer = -1;
    redo.setAttribute({visible:false});
    ////////////////////////ALL CHANGES GO HERE/////////////
    ////////////////////////////////////////////////////////
    //alpha.setAttribute({visible:true});
    //beta.setAttribute({visible:true});
});
/********************PLACE LOGO ********************/
var edliy = placeLogo(graph);
hoverMe(graph, edliy, 'Tap to see a hint', 0, 0);
edliy.on('down',
function(){
  k = toggle(k);
	vis = toggleTF(vis);
  bck.setAttribute({opacity:k});
	scoreCard.setAttribute({visible:false});
	missedCard.setAttribute({visible:false});
	wrongCard.setAttribute({visible:false});
  hint.setAttribute({visible:vis});
	question.setAttribute({visible:!vis});
	ansArray.forEach(function(item){item.setAttribute({visible:!vis})});
  note.setAttribute({visible:false});
  ////////////////////////Code specific changes go here/////////////////
	//alpha.setAttribute({visible:!vis});
  //beta.setAttribute({visible:!vis});
  //////////////////////////////////////////////////////////////////////
});
function eraseAngles(){
    circ11.setAttribute({visible:false});
    circ12.setAttribute({visible:false});
    circ13.setAttribute({visible:false});
    circ14.setAttribute({visible:false});
    angle1.setAttribute({visible: false});
    angle2.setAttribute({visible: false});
    angle3.setAttribute({visible: false});
    angle4.setAttribute({visible: false});
    angle5.setAttribute({visible: false});
    angle6.setAttribute({visible: false});
    angle7.setAttribute({visible: false});
    angle8.setAttribute({visible: false});
}
graph.unsuspendUpdate();

}
}
export default Boxes;
